import * as React from 'react';
import '../OurActions/OurActions.scss';
import { FoundationCardData } from '../../Home/types';
import { FC } from 'react';
import { ImageProps } from '../../_Shared/Writer/types';
import ROUTES from '../../../constants/routes';
import { navigate } from 'gatsby';
import { useSwiper } from 'swiper/react';
import { useSwiperSlide } from 'swiper/react';

interface OurActionsContentProps {
  title: string;
  mainImage: string;
  logoImage: ImageProps | undefined;
  logoImageSrc: string | undefined;
  description: string;
  foundationCard: FoundationCardData[];
}

const OurActionsContent: FC<OurActionsContentProps> = ({
  mainImage,
  title,
  foundationCard,
  description,
  logoImage,
  logoImageSrc,
}) => {
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  const slideIndex = swiper.realIndex;
  const slideIsActive = swiperSlide.isActive;
  const slidesData = swiper.slides;
  const amountSlides = swiper.slides.length;

  return (
    <div className="ourActions__block">
      <div className="ourActions__mainImage">
        <img src={mainImage} alt="#" />
        {amountSlides > 1 && (
          <div className="buttonsWrapper">
            {slidesData.map((_, index) => {
              const paginationBtnIsActive = slideIsActive && index === slideIndex ? 'btnSlider active' : 'btnSlider';

              return <button key={index} className={paginationBtnIsActive} onClick={() => swiper.slideTo(index)} />;
            })}
          </div>
        )}
      </div>
      <div className="ourActions__helpList">
        <h3>{title}</h3>
        {foundationCard.map(({ foundationName, icon, foundationDescription }: FoundationCardData, idx: number) => {
          const iconSrc = icon?.asset?.url;

          return (
            <div className="helpList_item" key={idx}>
              {icon && <img src={iconSrc} alt="#" />}
              <p className="foundationName">{foundationName && foundationName}</p>
              <p className="foundationDescription">{foundationDescription && foundationDescription}</p>
            </div>
          );
        })}
        <span className="helpList_description">{description}</span>
        {logoImage && (
          <div className="helpList_team">
            <span>TEAM — </span>
            <img onClick={() => navigate(ROUTES.HOME)} className="team_image" src={logoImageSrc} alt="#" />
          </div>
        )}
      </div>
    </div>
  );
};

export default OurActionsContent;
