import React from 'react';
import './DonateBanner.scss';
import { navigate } from 'gatsby';

interface DonateBannerData {
  banner: string[];
}

const DonateBanner = ({ banner }: DonateBannerData) => {
  return (
    <section className="donateBanner" onClick={() => navigate('#howSupport')}>
      <div className="donateBanner__wrapper">
        <div className="donateBanner__marquee">
          {banner.map((item: string, index: number) => {
            return (
              <div key={index} className="donateBanner__inner">
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default DonateBanner;
