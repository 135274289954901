import * as React from 'react';
import 'normalize.css';
import '../globalStyles/_fonts.scss';
import '../globalStyles/_styles.scss';
import PageLayout from '../components/_Shared/PageLayout/PageLayout';
import { supportUkraineTitle } from '../seoData/titles';
import { supportUkraineDescription } from '../seoData/descriptions';
import SupportUkraineContent from '../components/SupportUkraine';
import { HomeData } from '../components/Home/types';
import { graphql } from 'gatsby';

export const query = graphql`
  query SupportUkrainePageQuery {
    sanitySupportUkraine {
      _rawTechnologiesContent
      title
      banner
      whatLunkaDo {
        title
        description
        foundationList {
          title
          description
          mainImage {
            asset {
              url
            }
          }
          logoImage {
            asset {
              url
            }
          }
          foundationCard {
            foundationName
            foundationDescription
            icon {
              asset {
                url
              }
            }
          }
        }
      }
      supportRecommendations
      howToSupport {
        foundationTitle
        foundationIcon {
          caption
          alt
          image {
            asset {
              url
            }
          }
        }
        linkIcon {
          caption
          image {
            asset {
              url
            }
          }
        }
      }
      blockWithCardContent {
        cardTitle
        cardIcon {
          asset {
            url
          }
        }
      }
      userForm {
        managerName
        managerPosition
        description
        image {
          asset {
            url
          }
        }
        openFormLinks {
          title
          image {
            caption
            alt
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
    sanityLifestyle(id: { eq: "-923e6077-6a87-5c77-8cd0-38cd06a5e661" }) {
      title
      description
      images {
        asset {
          url
        }
      }
    }
  }
`;

const SupportUkraine = ({ data }: HomeData) => {
  const { allSanityFooter } = data;

  return (
    <PageLayout title={supportUkraineTitle} description={supportUkraineDescription} footerData={allSanityFooter}>
      <SupportUkraineContent data={data} />
    </PageLayout>
  );
};

export default SupportUkraine;
