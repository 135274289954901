import * as React from 'react';
import './OurActions.scss';
import { FoundationListData, WhatLunkaDoData } from '../../Home/types';
import OurActionsContent from '../OurActionsContent/OurActionsContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination, Autoplay } from 'swiper';

interface OurActionsProps {
  data: WhatLunkaDoData;
}

const OurActions = ({ data }: OurActionsProps) => {
  const { title, foundationList, description } = data;

  return (
    <section className="ourActions">
      <div className="ourActions__container">
        <div className="ourActions__head">
          <h2>{title}</h2>
          <span>{description}</span>
        </div>
        <Swiper
          autoplay={{ delay: 7000 }}
          slidesPerView={1}
          spaceBetween={0}
          keyboard={{ enabled: true }}
          modules={[Keyboard, Pagination, Navigation, Autoplay]}
        >
          <>
            {foundationList.map(
              ({ title, description, foundationCard, mainImage, logoImage }: FoundationListData, index: number) => {
                const mainImageSrc = mainImage.asset.url;
                const logoImageSrc = logoImage?.asset?.url;

                return (
                  <SwiperSlide key={index}>
                    <OurActionsContent
                      title={title}
                      description={description}
                      mainImage={mainImageSrc}
                      logoImage={logoImage}
                      logoImageSrc={logoImageSrc}
                      foundationCard={foundationCard}
                    />
                  </SwiperSlide>
                );
              }
            )}
          </>
        </Swiper>
      </div>
    </section>
  );
};

export default OurActions;
