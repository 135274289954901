import * as React from 'react';
import Main from '../_Shared/Main/Main';
import { BTN_STYLES } from '../_Shared/Button/Button';
import DonateBanner from '../_Shared/DonateBanner/DonateBanner';
import TechnologiesStack from '../_Shared/TechnologiesStack/TechnologiesStack';
import Lifestyle from '../AboutUs/Lifestyle/Lifestyle';
import HowToSupport from './HowToSupport/HowToSupport';
import OurActions from './OurActions/OurActions';
import { HomeData } from '../Home/types';
import BlockWithCard from '../_Shared/BlockWithCard/BlockWithCard';
import YourIdea from '../_Shared/YourIdea/YourIdea';
import { navigate } from 'gatsby';

const SupportUkraineContent = ({ data }: HomeData) => {
  const { sanityLifestyle, sanitySupportUkraine } = data;
  const {
    title,
    banner,
    blockWithCardContent,
    userForm,
    supportRecommendations,
    howToSupport,
    whatLunkaDo,
  } = sanitySupportUkraine;
  const technologiesContent = data.sanitySupportUkraine._rawTechnologiesContent;
  const navigation = () => navigate('#howSupport');

  return (
    <>
      <Main
        className="supportUkraine"
        title={title}
        btnTitle="Support Ukraine"
        classNameButton={BTN_STYLES.BTN_PRIMARY_MEDIUM}
        onClick={navigation}
      />
      <DonateBanner banner={banner} />
      <OurActions data={whatLunkaDo} />
      <Lifestyle imageHeight="440px" lifestyle={sanityLifestyle} />
      <TechnologiesStack data={technologiesContent} className="ukraine" />
      <HowToSupport howToSupport={howToSupport} supportRecommendations={supportRecommendations} />
      <BlockWithCard data={blockWithCardContent} />
      <YourIdea data={userForm} />
    </>
  );
};

export default SupportUkraineContent;
