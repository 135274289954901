import * as React from 'react';
import './HowToSupport.scss';
import { HowToSupportData } from '../../Home/types';
import ROUTES from '../../../constants/routes';

interface HowToSupportProps {
  supportRecommendations: string[];
  howToSupport: HowToSupportData[];
  title?: string;
}

const HowToSupport = ({
  supportRecommendations,
  howToSupport,
  title = 'How to Support Ukraine',
}: HowToSupportProps) => {
  return (
    <section id="howSupport" className="howSupport">
      <div className="howSupport__container">
        <h2>{title}</h2>
        <div className="howSupport__content">
          <div className="howSupport__solutions">
            {supportRecommendations.map((recommendation: string, index: number) => {
              return (
                <div className="howSupport__idea" key={index}>
                  <p>{recommendation}</p>
                </div>
              );
            })}
          </div>
          <div className="howSupport__referrals">
            {howToSupport.map(({ foundationTitle, foundationIcon, linkIcon }, index: number) => {
              const { image, alt, caption } = foundationIcon;
              const { image: linkImage, caption: linkCaption } = linkIcon;
              const foundationImageSrc = image.asset.url;
              const linkImageSrc = linkImage.asset.url;

              return (
                <div className="referrals_wrapper" key={index}>
                  <div className="referrals_emblem">
                    <img src={foundationImageSrc} alt={alt} />
                    <span>{foundationTitle}</span>
                  </div>
                  <div className="referrals_link">
                    <a className="referrals_link" target="_blank" href={caption}>
                      <img src={linkImageSrc} alt="#" />
                      <span>{linkCaption}</span>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <a href={ROUTES.HOW_TO_SUPPORT_UKRAINE} className="link">
          Check How Ukrainian Developers Continue Working in Wartime
        </a>
      </div>
    </section>
  );
};

export default HowToSupport;
